import { type } from '@testing-library/user-event/dist/type'

const Applications = {
  FILES: 'Files',
  WORDUP: 'WordUp',
  IMAGINE: 'Imagine',
  DIALOG: 'Dialog'
}

const FileTypes = {
  FOLDER: 'folder',
  IMG: 'img',
  DOCUMENT: 'doc',
  VIDEO: 'video',
}

const GoMenuItems = [
  {
    title: 'About this Max',
    event: 'about_this_max',
    icon: 'fa-solid fa-user'
  },
  {
    title: 'Applications',
    anchor: 'left',
    icon: 'fa-solid fa-list',
    items: [
      {
        title: 'WordUp',
        appLink: 'WordUp',
        icon: 'fa-solid fa-file-word',
      },
      {
        title: 'Imagine',
        icon: 'fa-solid fa-image',
      },
      {
        title: 'Snack',
        appLink: 'Game',
      }
    ]
  },
  {
    title: 'This Computer',
    event: 'about',
    icon: 'fa-solid fa-desktop'
  },
  {
    title: 'System Settings',
    appLink: 'Settings',
    icon: 'fa-solid fa-gear'
  },
  {
    title: 'Shut Down',
    event: 'shut_down',
    icon: 'fa-solid fa-power-off'
  }
]

// new proposed directories data
const NewDirectories = [
  {
    id: 0,
    name: 'Desktop',
    type: FileTypes.FOLDER, //This TYPE should live outside the datafile and in a constant file
    iconUrl: 'url/to/alt/icon.jpg', //optional
    data: {
      //here's where you put the application specific data e.g
      imgUrl: 'path/to/file.jpg', // if its a photos file,
      path: 'path/to/blog', //if it's a WordUp file
      items: [ // if it's a folder/directory
        {
          id: 1,
          name: 'Computer',
          type: FileTypes.FOLDER,
          iconUrl: 'ComputerIcon',
          data: {
            items: [
              {
                id: 11,
                name: 'Documents',
                type: FileTypes.FOLDER,
                data: {
                  items: [
                    {
                      id: 111,
                      name: 'How to center a div.tx',
                      type: FileTypes.DOCUMENT,
                      data: {
                        blogId: 16
                      }
                    },
                    {
                      id: 113,
                      name: 'Hyp Qs.tx',
                      type: FileTypes.DOCUMENT,
                      data: {
                        blogId: 14
                      }
                    },
                    {
                      id: 114,
                      name: 'The Subtleties of Snake.tx',
                      type: FileTypes.DOCUMENT,
                      data: {
                        blogId: 13
                      }
                    },
                    {
                      id: 115,
                      name: 'The PullTab Component.tx',
                      type: FileTypes.DOCUMENT,
                      data: {
                        blogId: 12
                      }
                    },
                    {
                      id: 116,
                      name: 'Pogo Moyo Part 1.tx',
                      type: FileTypes.DOCUMENT,
                      data: {
                        blogId: 11
                      }
                    },
                  ]
                }
              },
              {
                id: 12,
                name: 'Images',
                type: FileTypes.FOLDER,
                data: {
                  items: [
                    {
                      id: 121,
                      name: 'Hitpoint Card.jpg',
                      type: FileTypes.IMG,
                      data: {
                        imgUrl: 'https://i.imgur.com/2wpjgdj.jpeg',
                      }
                    },
                    {
                      id: 122,
                      name: 'Gold Card.jpg',
                      type: FileTypes.IMG,
                      data: {
                        imgUrl: 'https://i.imgur.com/WmcMG8b.jpeg',
                      }
                    },
                    {
                      id: 125,
                      name: 'DIY Planter.jpg',
                      type: FileTypes.IMG,
                      data: {
                        imgUrl: 'https://i.imgur.com/TLAuwic.jpeg',
                      }
                    },
                    {
                      id: 126,
                      name: 'Veg.jpg',
                      type: FileTypes.IMG,
                      data: {
                        imgUrl: 'https://i.imgur.com/DPM5Sq2.jpeg',
                      }
                    },
                    {
                      id: 123,
                      name: 'Wedding Invite.jpg',
                      type: FileTypes.IMG,
                      data: {
                        imgUrl: 'https://i.imgur.com/GQ9tnWR.png',
                      }
                    },
                    {
                      id: 124,
                      name: 'Hugos Logo.jpg',
                      type: FileTypes.IMG,
                      data: {
                        imgUrl: 'https://i.imgur.com/DEMDP7p.jpeg',
                      }
                    },
                  ]
                }
              },
              {
                id: 13,
                name: 'Videos',
                type: FileTypes.FOLDER,
                data: {
                  items: [
                    {
                      id: 131,
                      type: FileTypes.VIDEO,
                      name: 'Pogo Moyo Trailer',
                      data: {
                        src: 'https://www.youtube.com/watch?v=ZOMz5xbBlQE',
                        type: 'YouTube',
                      }
                    },
                    {
                      id: 132,
                      type: FileTypes.VIDEO,
                      name: 'DIY Drawers',
                      data: {
                        src: 'https://www.youtube.com/watch?v=1xGgTNw2-yY',
                        type: 'YouTube',
                      }
                    },
                  ]
                }
              }
            ]
          }
        },
        {
          id: 2,
          name: 'Shorts',
          type: FileTypes.FOLDER,
          data: {
            items: [
              {
                id: 5,
                name: 'What are Sprint Shorts.tx',
                type: FileTypes.DOCUMENT,
                data: {
                  blogId: 10
                }
              },
              {
                id: 21,
                type: FileTypes.VIDEO,
                name: 'X Gon Gift It To Ya',
                data: {
                  src: 'https://www.youtube.com/watch?v=0UXHxICMHlg',
                  type: 'YouTube',
                }
              },
              {
                id: 22,
                type: FileTypes.VIDEO,
                name: 'Jelly House Rock',
                data: {
                  src: 'https://www.youtube.com/watch?v=H9remS7zR6w',
                  type: 'YouTube',
                }
              },
              {
                id: 23,
                type: FileTypes.VIDEO,
                name: 'Eye of the Cider',
                data: {
                  src: 'https://www.youtube.com/watch?v=SWI-il1avAs',
                  type: 'YouTube',
                }
              },
              {
                id: 24,
                type: FileTypes.VIDEO,
                name: 'Crazy in Latte',
                data: {
                  src: 'https://youtube.com/shorts/r_NUnL3_Wf8',
                  type: 'YouTube',
                }
              },
              {
                id: 25,
                type: FileTypes.VIDEO,
                name: 'Another One Bites the Donut',
                data: {
                  src: 'https://www.youtube.com/shorts/8gw-C79fWoI',
                  type: 'YouTube',
                }
              },
              {
                id: 26,
                type: FileTypes.VIDEO,
                name: 'Uptown Dunk',
                data: {
                  src: 'https://youtube.com/shorts/zGZt3LM3LlU',
                  type: 'YouTube',
                }
              }
            ]
          }
        },
        {
          id: 4,
          type: FileTypes.VIDEO,
          name: 'PG Xmas.mp4',
          data: {
            title: 'PG Xmas.mp4',
            src: 'https://www.youtube.com/watch?v=muqtdFLU7pI',
            type: 'YouTube',
            aspectRatio: '16 / 9'
          }
        },
        {
          id: 5,
          name: 'Hypothetical Questions.tx',
          type: FileTypes.DOCUMENT,
          data: {
            blogId: 14
          }
        },
        {
          id: 6,
          name: 'Me.jpg',
          type: FileTypes.IMG,
          data: {
            imgUrl: 'https://i.imgur.com/eYhetwN.jpg',
          }
        },
        {
          id: 7,
          type: FileTypes.VIDEO,
          name: 'San Fran.vid',
          data: {
            title: 'San Francisco',
            src: 'https://www.youtube.com/watch?v=0LG-HZ1ETHw',
            type: 'YouTube',
            aspectRatio: '16/9',
          }
        },
        {
          id: 8,
          name: 'Posters.jpgs',
          type: FileTypes.IMG,
          data: {
            imgurAlbumId: '5bLbifG',
          }
        },
        {
          id: 9,
          type: FileTypes.VIDEO,
          name: 'X Gon Gift It To Ya',
          data: {
            src: 'https://www.youtube.com/watch?v=0UXHxICMHlg',
            type: 'YouTube',
          }
        },
      ]
    },
  },
]

const Directories = [
  {
    id: 0,
    name: 'Desktop',
    application: FileTypes.FOLDER.application,
    type: FileTypes.FOLDER.type,
    items: [
      {
        id: 1,
        name: 'Computer',
        application: FileTypes.FOLDER.application,
        type: FileTypes.FOLDER.type,
        imgsrc: 'https://www.svgrepo.com/show/456199/computer-monitor.svg',
      },
      {
        id: 2,
        name: 'My Folder',
        application: FileTypes.FOLDER.application,
        type: FileTypes.FOLDER.type,
      },
      {
        id: 3,
        name: 'Meme.jpg',
        application: FileTypes.IMG.application,
        type: FileTypes.IMG.type,
      },
    ]
  },
  {
    id: 4,
    name: 'Documents',
    application: FileTypes.FOLDER.application,
    type: FileTypes.FOLDER.type,
    items: [
      {
        id: 5,
        name: 'PogoMoyo.tex',
        application: FileTypes.DOCUMENT.application,
        type: FileTypes.DOCUMENT.type,
        route: '/blog/pogomoyo'
      },
    ]
  },
  {
    id: 6,
    name: 'Photos',
    application: FileTypes.FOLDER.application,
    type: FileTypes.FOLDER.type,
  }
]

const BlogPosts = [
  {
    postId: 16,
    hidden: false,
    title: 'How to center a div in 2024',
    titleImg: 'https://i.imgur.com/K89OtCg.png',
    subTitle: 'A new attempt to solve the oldest html question.',
    content: `<div>
      ## How to center a div in 2024
      I can't believe I've found myself asking this question again. Centering divs has always been an interesting problem. There have been many standard approches to this problem over the years. Auto margins, asbsolute positioning, text-align, etc. Then flexbox and grid came along and trivialised the whole process. I haven't had to google this question in years. But here we are.

      When building this site I wanted to let visitors resize windows freely. For most applications this just meant that I had to apply responsive design to any mock applications I created. When creating the video player, that window flexibility meant I had to account for when the window was taller or wider than the video's aspect ratio. The easier way to handle this would have been to just lock the aspect ratio of the window to that of the video. But I wanted to mimic the look and feel of the QuickTime video player circa 2008. Obviously the gold standard of native OSX video players.
      
      This meant the video application had to simultaneously handle letter-boxing and pillar-boxing. And so, in 2024, I found myself once again asking the question: how do you center a div? Or I guess more speifically: how do you render videos in their original aspect-ratio at the maximum possible size, centered inside a container that can be resized to any dimensions.

      I went back and forth on a few solutions here. I wanted to avoid listening for window resizes as I felt there must be a css solution available. But this is harder than you might think. Because a div can either expand horizontally and have it's height scale according to the videos aspect ratio, OR it can expand vertically and have it's width scale according to the videos aspect ratio. But it can't do both. Or at least not without..
      
      ### Container Queries

      Container queries don't just allow you to apply styles based on the width of a given element. There's a whole library of media features and this time we're using "min-aspect-ratio".

      Here's the main engine of our solution. It's so simple (please excuse the styled components syntax):

      <code>
      width: 100%;<br><br>
      @container (min-aspect-ratio: ${props => props.$aspectRatio}) {<br>
      &nbsp;&nbsp;&nbsp;&nbsp;width: auto;<br>
      &nbsp;&nbsp;&nbsp;&nbsp;height: 100%;<br>
      }
      </code>
      
      By default we have the video container stretch horizontally however as soon as the aspect ratio of the container exceeds the aspect ratio of the video, the container will switch to a vertical stretch. This means that the video will always be centered and at it's maximum possible size.

      ##Pros
      - I managed to get the exact effect I was looking for without any greedy JS listeners.

      ##Cons
      - Container query support is somewhat limited. And not just by browsers. Styled componenets has only just added support for container queries.
      - None of this was necessary

      </div>`,
    timestamp: 'Tuesday July 25, 2023',
  },
  {
    postId: 15,
    hidden: true,
    title: 'Doors OS',
    titleImg: 'https://cdn.wikimg.net/en/splatoonwiki/images/thumb/5/51/S2_Splatfest_Flight_vs_Invisibility.png/800px-S2_Splatfest_Flight_vs_Invisibility.png',
    subTitle: 'A self-gratifying nostalgia project',
    content: `<div>
      ## Doors OS (Document Object-Model Oriented React-baSed Operating System)
      
      I realised recently that I sometimes date my memories based on what computer operating systems were available to me at that time. For example, I might not remember exactly what year my friend fell from our ramshackle tree house and broke his arm, but I do remember that I wrote his 'Get Well Soon' card using Micorosft Publisher which we installed when we upgraded to Windows 98.

      As such I have quite a strong sense of nostaliga around these older systems. Whenever we upgraded to a new OS I would spend hours just cluelessly meandering around the directories, settings and pre-installed applications. I understood so little of what I was looking at and it all felt so overwhelmingly new. But slowly it would start to click and as I adjusted the backgrounds and startup sounds and the directories became poluted with funny wav files and animations, the computer would in a weird way come to refelct who I was in that moment.

      So when I was in the market for a new portfolio site I figured this might just be the perfect project. An operating system on the web. It has some really unique advantages too. The UX doesn't overly benefit one content format over another. After all Operating Systems have to be able to render photos, videos and text. Everyone knows how to use them already too. It's ripe for creativity and easter-eggs. It's been a perfect exercise in self-celebration which is ultimately what a portfolio site should be.

      I especially like that I can update it in ways I've never been able to update a site before. If I want some fresh piece of content front and center I don't have to worry about whether or not it can be adequately added to a new blog post. I can just drop it on the desktop with no explanation. And that's fine, that's exactly what this site demands. No matter what state I'm in I can always adequately reflect it by rearranging files and folders or creating new games or dumping a hundred new images onto the desktop. And in that sense it does feel like the old days. I'm slowly making this new system my own.

      <!--### RISC OS (1993 - 1996)
      To my knowledge this was the first computer operating system I interacted with. It was installed on the handful of computers we had around our local primary school. My teacher once asked me to use the paint software to draw a picture of my house. I somehow misinterpreted these simple instructions and drew a picture of Sonic the hedgehog running over a rainbow instead.
      ### Windows 95/98 (1996 - 1998)
      I arrived home one day and my parents had FINALLY given in to the new age and bought a brand new PC. Boasting a handful of megabytes of RAM and a Pentium processor.
      ### Windows 3.1 (2001)
      My secondary school had a outdated computer lab. This OS was a step back from the Windows OS we had at home but it came pre-loaded with corocodile clips which we all seemed to enjoy far more than it deserved.
      ### Windows XP (2002 - 2005)
      That same computer was -->

      </div>`,
    timestamp: 'Tuesday July 25, 2023',
  },
  {
    postId: 14,
    title: 'Hypothetical Questions',
    subTitle: 'The top 4 hypothetical questions of all time',
    titleImg: 'https://i.imgur.com/2fb1s5P.jpeg',
    content: `<div>
      ## Hypothetical Questions - The top 4 hypothetical questions of all time

      ### 1. Flight vs Invisibility?
      Be sure to clearly define your rules and restrictions ahead of time because this one can get shouty. [John Hodgman's take](https://www.thisamericanlife.org/178/transcript).
      
      <strong>What it tells us</strong>
      - Do you want to save people from burning buildings or rob banks? Travel for free or sneak into movies? Be seen or unseen?
      
      <strong>Rules to consider</strong>
      - You can carry no more while flying than you already can walking.
      - Only your clothes turn invisible with you.
      
      <strong>Min/Max questions to watch out for</strong>
      - "Does a puffer-jacket lined with cash count as clothes?"
      - "What is the airspeed velocity of an unladen human?"
      
      ### 2. Would you rather be an accomplished musician, actor or writer?
      
      <strong>What it tells us</strong>
      - Do you have a secret talent we're not aware of?
      - Do you value fame or anonymity? Adventure or comfort? Solitude or adoration?
      
      <strong>Rules to consider</strong>
      - You must also define your genre.
      - The level of your success is the same between all three.

      <strong>Min/Max questions to watch out for</strong>
      - "So they all make the same BASE salary.. but what about merch sales?"
      - "Do I get overtime pay if I'm playing a late night gig?"
      - "Can I just write Haikus?"


      ### 3. What’s the stupidest thing you bought as a teenager?
      Inspired by my own purchase of a sword from ebay at the age of 16.
      
      <strong>What it tells us</strong>
      - Hopefully not much. I haven't bought anymore swords so that's a good sign.

      <strong>Rules to consider</strong>
      - The item must have been purchased with your own money.
      - The item must have been purchased between the ages of 14 and 17.

      <strong>Min/Max questions to watch out for</strong>
      - "Why is a sword a stupid purchase?"
      - "Forged or folded steel?"

      ### 4. What animal would you transform into to sneak across the country and how would you do it?
      Our version of the question was as follows. You have to get from Vancouver to Penticton with the entire country's police and armed forces hunting you. You can turn into one species of animal to help avoid detection but the forces against you will be informed of your choice.
      
      <strong>What it tells us</strong>
      - How much patience you have for hypothetical questions.

      <strong>Rules to consider</strong>
      - You can only transform twice.
      - No flight!

      <strong>Min/Max questions to watch out for</strong>
      - "Can we just get back to work now?"
    </div>`,
    timestamp: 'Thursday July 14, 2022',
  },
  {
    postId: 13,
    title: 'The Subtleties of Snake',
    subTitle: 'How I recreated the most important feature of Snake',
    titleImg: 'https://i.imgur.com/UhGNgUp.jpeg',
    content: `<div>
      ## The Subtleties of Snake
      The game Snake is significantly older than I thought. The core gameplay was first released in 1976 under the title Blockade. 1976! That means the game was already 22 years old by the time it made it to Nokia devices.

      Now if you want to go and build your own version of snake you can do so in a few hours. It's a great project for beginners and it's a great way to learn about game loops and collision detection. But if you want to build a version of snake that feels like the Nokia version you're going to have to spend a little more time on it.

      The original Nokia version of snake had a subtle but crucial feature that a lot of online versions miss. In these versions of Snake, if you change the snake's direction it will instantly update the next expected location of the Snake's head. This means that if you try to make two moves within one frame, only the last will be remembered.

      What Nokia did was add an input buffer. This would mean the game would remember two input presses, not one. So if you wanted to turn up and then immediately right, you could press the 2 and then 6 within one frame and the Snake would move up, and then at the next frame move right. This would allow you to 'staircase' across the play space and turn 180˚ on a dime.
      
      <img src="https://i.imgur.com/ltsIMkn.gif" alt="An animation showing a snake implementation with an input buffer and one without" />

      This is a subtle feature but it's one that makes the game feel so much more responsive and enjoyable to play. [Play Snack on this site now!](TODO: Add link to snack game)
    </div>`,
    timestamp: 'Wednesday October 27, 2023',
  },
  {
    postId: 12,
    title: 'The PullTab Component',
    titleImg: 'https://i.imgur.com/GoQyOPs.jpeg',
    content: `<div>
      ## The PullTab Component
      If you're not familiar, pulltabs are a form of gambling where you buy a ticket and pull back a series of tabs to reveal if you've won a prize. They're essentially low value scratch cards but the thing that makes them so appealing is the sensation of tearing off the perforated strips of paper. It's much more gratifying than scratching away at silvery latex.

      I'm personally not interested in gambling however I love a good UI challenge, so I set about creating a pulltab component in React. The final result you can see below.

      <iframe height="306.640625" style="width: 100%;" scrolling="no" title="Pull Tab Component" src="https://codepen.io/maxkhoward/embed/poYNxJW?default-tab=result%2Cresult" frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true">
        See the Pen <a href="https://codepen.io/maxkhoward/pen/poYNxJW">
        Pull Tab Component</a> by Max Howard (<a href="https://codepen.io/maxkhoward">@maxkhoward</a>)
        on <a href="https://codepen.io">CodePen</a>.
      </iframe>

    </div>`,
    timestamp: 'Monday December 07, 2020',
  },
  {
    postId: 11,
    hidden: true,
    title: 'Pogo Moyo part 1',
    titleImg: 'https://pbs.twimg.com/profile_images/1170835484169273344/J2FN5h9J_400x400.jpg',
    content: 'My Bachelors thesis allowed me to play with some clever locomotion mechanics. These mechanics taught me ....',
    timestamp: 'Monday December 07, 2020',
  },
  {
    postId: 10,
    hidden: true,
    title: 'What are Sprint Shorts?',
    titleImg: 'https://learn.g2.com/hubfs/iStock-1054858914.jpg',
    content: 'Every year I try and come up with a new naming convention (and corresponding ceremonies) for our teams\' sprints. For the year of 2023 we deided to set ourselves a bit of a challenge. We would name each sprint after a modified song title. We would then film a short (approx 10 second) video related to the sprint name. These videos were then presented to the rest of the organization at the end of our sprint demos. These are a handful of my better Sprint Shorts.', 
    timestamp: 'Monday December 07, 2024',
  },
]

const Pages = [

]

export { Applications, Pages, BlogPosts, GoMenuItems, Directories, NewDirectories, FileTypes }