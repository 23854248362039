import React, {useState, useRef, useContext, useEffect} from "react";
import styled from "styled-components";
import Draggable from "react-draggable";
import TitleBar from "./TitleBar";
import { ApplicationContext } from "../../contexts/ApplicationContext";

const WindowContainer = styled.div`
  position: absolute;
  // replace with prop values
  top: ${props => props.$maximised ? `0` : props.$positionY+'px'};
  left: ${props => props.$maximised ? `0` : props.$positionX+'px'};
  ${props => props.$maximised ? 
    `height: calc(${window.innerHeight}px - ${props.theme.goBar.height} - ${props.theme.border.size.primary});` :
    `height: ${props.height}px;`
  }
  width: ${props => props.$maximised ? `100vw` : props.width+'px'};
  background: ${props => props.theme.backgroundColors.window};
  color: ${props => props.theme.colors.textColor};
  border-radius: ${props => props.theme.border.radius.primary};
  border: 2px solid ${props => props.theme.colors.borderColor1};
  box-shadow: 4px 4px 0px rgba(0,0,0,0.3);
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const WindowView = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
`

const ResizeHandle = styled.div`
  position: absolute;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  cursor: se-resize;
`;

export default Window = ({
  title,
  id,
  children,
  Icon,
  width,
  height,
  canMaximise=true,
  center=false,
  top=null,
  bottom=null,
  left=null,
  right=null,
  minWidth=null,
  minHeight=null,
}) => {
  const { windows, setActiveWindow } = useContext(ApplicationContext)
  const initialHeight = height || 600;
  const initialWidth = width || 570;
  let initialPositionX = 100;
  let initialPositionY = 100;
  const ResizeHandleSize = 20;
  const nodeRef = useRef(null);
  const resizeHandleRef = useRef(null);

  const [position, setPosition] = useState({x:100, y: 100});
  const [size, setSize] = useState({width: initialWidth, height: initialHeight});
  const [maximised, setMaximised] = useState(false);
  const minimiumWidth = minWidth || 240;
  const minimiumHeight = minHeight || 240;

  useEffect(()=>{
    center ? 
      setPosition({ x: document.body.clientWidth/2 - initialWidth/2, y: document.body.clientHeight/2 - initialHeight/2 }) :
      setPosition({ x: windows.length*50, y: windows.length*50 });
    
    if (initialHeight + initialPositionY >= window.innerHeight || initialWidth + initialPositionX >= window.innerWidth) {
      setMaximised(true)
    }
  },[])

  const handleResize = (e) => {
    setSize({
      height: Math.max(minimiumHeight, size.height + e.movementY),
      width: Math.max(minimiumWidth, (size.width + e.movementX))
    });
  }

  const maximisedProps = {}
  if (maximised) {
    maximisedProps.position = {
      x: 0,
      y: 0,
    }
  }

  return(
    <Draggable nodeRef={nodeRef} handle=".handle" {...maximisedProps} onStart={() => setActiveWindow(id)} cancel=".title-bar-button">
      <WindowContainer
        ref={nodeRef}
        className="window-container"
        width={size.width}
        height={size.height}
        $maximised={maximised}
        $positionX={position.x}
        $positionY={position.y}
      >
        <TitleBar
          title={title}
          id={id}
          Icon={Icon}
          classProp={!maximised && "handle"}
          onClickMaximise={() => setMaximised(!maximised)}
          canMaximise={canMaximise}
        />
        <WindowView onMouseDown={() => setActiveWindow(id)}>{children}</WindowView>
        <Draggable
          nodeRef={resizeHandleRef}
          onDrag={handleResize}
          defaultPosition={{ x: initialWidth-ResizeHandleSize, y:initialHeight-ResizeHandleSize }}
          position={{x: size.width-ResizeHandleSize, y: size.height-ResizeHandleSize}}
        >
          <ResizeHandle
            ref={resizeHandleRef}
            className="resizeHandle"
            size={ResizeHandleSize}
          />
        </Draggable>
      </WindowContainer>
    </Draggable>
  )
}