import React, { useState, useEffect } from 'react';
import Window from '../Window/Window';
import styled from 'styled-components';
import Toolbar from '../Window/Toolbar';
import { Icons } from '../../assets/Icons';

const ImgContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.$imgUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

const ImgurContainer = styled.div`
  width: 100%;
  height: auto;
  transform: scale(0.7);
  transform-origin: 40% 0;
`

const ImgWrapper = styled.div`
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ImagineAppContainer = styled.div`
  display: flex;
  background: ${props => props.theme.backgroundColors.imagine};
  height: 100%;
  border-top: 2px solid #555;
`

const SepiaIcon = styled(Icons.PhotoIcon)`filter: sepia(50%);`
const BlackAndWhiteIcon = styled(Icons.PhotoIcon)`filter: grayscale(100%);`
const SaturateIcon = styled(Icons.PhotoIcon)`filter: saturate(250%);`
const InvertIcon = styled(Icons.PhotoIcon)`filter: invert(70%);`
const BrightIcon = styled(Icons.PhotoIcon)`filter: brightness(150%);`
const BlurIcon = styled(Icons.PhotoIcon)`filter: blur(1px);`


const Imagine = ({windowId, fileName, imgUrl, imgurAlbumId}) => {
  const [ currentFilter, setCurrentFilter ] = useState('');

  useEffect(() => {
    if (imgurAlbumId) {
      const script = document.createElement('script');
    
      script.src = "//s.imgur.com/min/embed.js";
      script.async = true;
    
      document.body.appendChild(script);
    
      return () => {
        document.body.removeChild(script);
      }
    }
  }, []);

  const RenderImage = (imgUrl, imgurAlbumId) => {
    if (imgUrl) {
      return (
        <ImgContainer $imgUrl={imgUrl} alt='' style={{ filter: currentFilter }} />
      )
    } else if (imgurAlbumId) {
      return (
        <ImgurContainer>
          <blockquote className="imgur-embed-pub" lang="en" data-id={`a/${imgurAlbumId}`}>
            <a href={`//imgur.com/${imgurAlbumId}`}>sksksks</a>
          </blockquote>
          <script async src="//s.imgur.com/min/embed.js" charSet="utf-8"></script>
        </ImgurContainer>
      )
    }
  }

  return (
    <Window
      id={windowId}
      title={fileName}
    >
      <ImagineAppContainer>
        <Toolbar
          vertical={true}
          buttons={[
            {
              icon: SepiaIcon,
              disabled: false,
              onClick: () => {
                setCurrentFilter(`sepia(50%)`)
              },
              label: 'See all blogs'
            },
            {
              icon: BlackAndWhiteIcon,
              disabled: false,
              onClick: () => {
                setCurrentFilter(`grayscale(100%)`)
              },
              label: 'See all blogs'
            },
            {
              icon: SaturateIcon,
              disabled: false,
              onClick: () => {
                setCurrentFilter(`saturate(150%)`)
              },
              label: 'See all blogs'
            },
            {
              icon: InvertIcon,
              disabled: false,
              onClick: () => {
                setCurrentFilter(`invert(75%)`)
              },
              label: 'See all blogs'
            },
            {
              icon: BrightIcon,
              disabled: false,
              onClick: () => {
                setCurrentFilter(`brightness(150%)`)
              },
              label: 'See all blogs'
            },
            {
              icon: BlurIcon,
              disabled: false,
              onClick: () => {
                setCurrentFilter(`blur(5px)`)
              },
              label: 'See all blogs'
            },
          ]}
        />
        <ImgWrapper>
          { RenderImage(imgUrl, imgurAlbumId) }
        </ImgWrapper>
      </ImagineAppContainer>
    </Window>
  )
}

export default Imagine;