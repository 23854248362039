import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconRenderer, Icons } from '../assets/Icons';
import Button from './Buttons/Button';

const NetworkContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

const AnimationContainer = styled.div`
  opacity: ${props => props.$isAnimating ? '1' : '0.3'};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 12px;
`;

const NetworkTextContainer = styled.div`
  min-width: 70px;
  text-align: left;
`

const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-bottom: 25px;
`;

const Globe0 = () => <Icons.Globe offset={-100} />;
const Globe1 = () => <Icons.Globe offset={-50} />;
const Globe2 = () => <Icons.Globe offset={0} />;
const Globe3 = () => <Icons.Globe offset={50} />;
const Globe4 = () => <Icons.Globe offset={100} />;
const Globe5 = () => <Icons.Globe offset={150} />;

const FrameCount = 12
const Globes = []
const LoadingDots = []
const NetworkingFrames = []
for (var i = 0; i < 12; i++) {
  const Gl = Icons.Globe({offset: -100 + 25*i})
  const Lo = Icons.LoadingDots({dot:i%6})
  NetworkingFrames.push({
    loadingDots: () => Lo,
    Globe: () => Gl,
    NetworkText: `Connecting${i%3===0 ? '.' : i%3===1 ? '..' : '...'}`
  })
  Globes.push(Icons.Globe({offset: -100 + 25*i}))
  LoadingDots.push(Icons.LoadingDots({dot:i%6}))
}

const ErrorFrames = [
  {
    computer: Icons.ComputerSad,
    dots: Icons.LoadingDots,
    Globe: Icons.Globe,
    NetworkText: `You have network connectivity problems`
  }
]

const Network = () => {
  const FrameDelay = 300;
  const ErrorDelay = 5000;
  const DefaultComputerImage = Icons.ComputerIcon;
  const DefaultDotsIcon = Icons.LoadingDots0;
  const DefaultGlobeIcon = Icons.Globe0;

  const [currentFrame, setCurrentFrame] = useState(0);
  const [millisPassed, setMillisPassed] = useState(0);
  const [error, setError] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [errorIndex, setErrorIndex] = useState(0);

  const onClickConnect = () => {
    setCurrentFrame(0)
    setError(false)
    setMillisPassed(0)
    setHasStarted(true)

    const interval = setInterval(() => {
      setCurrentFrame(oldCurrentFrame => {
        if (oldCurrentFrame >= NetworkingFrames.length - 1) {
          return 0
        }
        return oldCurrentFrame + 1
      });
      setMillisPassed(oldMillisPassed => {
        if(oldMillisPassed >= ErrorDelay) {
          clearInterval(interval);
          setErrorIndex(Math.floor(Math.random()*ErrorFrames.length));
          setError(true);
        }
        return oldMillisPassed + FrameDelay
      });
      
      if (currentFrame >= NetworkingFrames.length - 1){
        setCurrentFrame(0);
      }
      if (millisPassed >= ErrorDelay) {
        
      }
    }, FrameDelay);
    return () => clearInterval(interval);
  };

  const Computer = !hasStarted ? 
    NetworkingFrames[currentFrame].computer || DefaultComputerImage : error ?
    ErrorFrames[errorIndex].computer :
    NetworkingFrames[currentFrame].computer || DefaultComputerImage;
  const Dots = !hasStarted ?
    NetworkingFrames[currentFrame].loadingDots : error ?
    ErrorFrames[errorIndex].dots :
    NetworkingFrames[currentFrame].loadingDots;
  const Globe = !hasStarted ? 
    NetworkingFrames[5].Globe : error ?
    ErrorFrames[errorIndex].Globe :
    NetworkingFrames[currentFrame].Globe;
  const NetworkText = !hasStarted ?
    'disconnected' : error ?
    ErrorFrames[errorIndex].NetworkText :
    NetworkingFrames[currentFrame].NetworkText;

  return (
      <NetworkContainer>
        <AnimationContainer $isAnimating={hasStarted && !error}>
          <IconRenderer Component={Computer} width={82} />
          <IconRenderer Component={Dots} />
          <IconRenderer Component={Globe} />
        </AnimationContainer>
        <NetworkTextContainer>
          {NetworkText}
        </NetworkTextContainer>
        { (!hasStarted || error) &&
          <ButtonContainer>
            <Button onClick={() => onClickConnect()}>Connect</Button>
          </ButtonContainer>
        }
      </NetworkContainer>
  )
}

export default Network;